import React, { Component } from 'react';
import Header from '../../components/Shared/Header/Header';
import HeroUnit from '../../components/Shared/HeroUnit/HeroUnit';
import Footer from '../../components/Shared/Footer/Footer';
import ContactBanner from '../../assets/images/contact/contact-defreitas-nutrition.jpg';
import BodyHeader from '../../components/Shared/BodyHeader/BodyHeader';
import ContactForm from '../../components/Contact/ContactForm/ContactForm';
import MetaData from '../../components/Shared/MetaData/MetaData';
import styles from './Contact.module.css';

class Contact extends Component {
  render() {
    return (
      <div>
        <MetaData id={7} />
        <Header/>
        <HeroUnit 
            heroImage={ContactBanner} 
            heroH1='We are ready to exceed your consumer needs through science'
            heroH1Style={styles.heroH1}/>
        <BodyHeader first='GET IN TOUCH WITH ' last=' DEFREITAS NUTRITION' />
        <ContactForm />
        <Footer/>
      </div>
    );
  }
}

export default Contact;


