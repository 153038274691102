import React from 'react';
import { Media } from 'reactstrap';
import BodyHeader from '../../Shared/BodyHeader/BodyHeader';
import { AFFILIATIONS } from '../../../shared/constants';
import styles from './AboutZDFEducAffil.module.css';

const RenderAboutZDFEducAffil = ({affiliation}) => {
    return (
        <Media>
            <Media >
                <Media object src={affiliation.img} alt={affiliation.altText} className={styles.educAffImg}/>
            </Media>
            <Media body>
                <Media heading className={styles.title}> {affiliation.title1} </Media>
                    <p className={styles.description} dangerouslySetInnerHTML={{__html: affiliation.description1}} />
                <Media heading className={styles.title}> {affiliation.title2} </Media>
                    <p className={styles.description} dangerouslySetInnerHTML={{__html: affiliation.description2}} />
            </Media>
        </Media>
    );     
}

const AboutZDFEducAffil  = () => {
    return (
        <div className='container'>
            <BodyHeader first='EDUCATION' last=' ' />
            <div className='row'>
                
                <RenderAboutZDFEducAffil affiliation={AFFILIATIONS.find((educAff) => educAff.id === 0)} />
            </div>
           
            <BodyHeader first='PROFESSIONAL DEVELOPMENT  ' last=' & AFFILIATIONS' />
            <div className={` row ${styles.affilRow}`}>
                <RenderAboutZDFEducAffil affiliation={AFFILIATIONS.find((educAff) => educAff.id === 1)} />
            </div>
        </div>
    );
}

export default AboutZDFEducAffil;