import React, {Component} from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Button
} from 'reactstrap';
import styles from './Publication.module.css';

class Publication extends Component {
    state = {
        show: false,  
    }

    toggleContent(previousState) {
        this.setState({ show: !previousState.show });
    }

    render(){
        return (
            <Card className={styles.card} variant="success" >
                <CardImg className={styles.img} top src={this.props.src} alt={this.props.alt} />
                <CardBody>
                    <CardTitle className={styles.title}>{this.props.cardTitle}</CardTitle>
                    <CardText className={styles.description} >
                        {this.state.show && <p  dangerouslySetInnerHTML={{__html: this.props.cardText}}/>}
                    </CardText>
                    <Button className={styles.button} onClick={() => this.toggleContent(this.state)} >
                        <span aria-hidden="true">{this.state.show ? '-' : '+'}</span>
                    </Button>
                </CardBody>
            </Card>
        );
    }
}

export default Publication;
