import React from 'react';
import { Media } from 'reactstrap';
import styles from './AboutDFNValues.module.css';
import MissionImg from '../../../assets/images/service-product-innovation/defreitas-nutrition-mission.png';
import ValuesImg from '../../../assets/images/service-product-innovation/defreitas-nutrition-values.png';
import VisionImg from '../../../assets/images/common/product-innovation.png';

const VALUES =
[
    {
        id: 0,
        name: 'MISSION',
        description: 'Be a respected, recognized and successful, science-based nutritional company.',
        image: MissionImg
    },
    {
        id: 1,
        name: 'VALUES',
        description: '<ul> <li>Search for win-win solutions</li> <li>Entrepreneurial spirit</li> <li>Passion and hard work</li> <li>Scientific focused</li> <li>Confidentiality</li> </ul>',
        image: ValuesImg
     },
     {
        id: 2,
        name: 'VISION',
        description: "To improve people's life and health by providing science-based innovative products and services to the nutrition industry and consumers.",
        image: VisionImg
    }
]

const RenderAboutValues = ({value}) => {
    return (
        <Media>
            <Media className={styles.img}>
                <Media object src={value.image} alt={value.name}/>
            </Media>
            <Media body>
                <Media heading className={styles.name}> {value.name} </Media>
                <p className={styles.description} dangerouslySetInnerHTML={{__html: value.description}} />
            </Media>
        </Media>
    );     
}

const AboutValues = () => {
    const aboutValues = VALUES.map((value) => {
        return (
            <div className='col-12 col-sm-6'  key={value.id}>
                    <RenderAboutValues value={value} />
            </div>
        );
    });

    return (
        <div className='container'>
            <div className={`row ${styles.values}`}>
                {aboutValues}
            </div>
        </div>
    );
}

export default AboutValues;