import React from 'react';
import ClickableButton from '../../Shared/ClickableButton/ClickableButton';
import BodyHeader from '../../Shared/BodyHeader/BodyHeader';
import HomeFeaturesImg from '../../../assets/images/home/home-features.jpg';
import styles from './HomeFeatures.module.css';

const HomeFeatures = () => {
    return(
        <div className='container'>
            <BodyHeader first='SUPPLEMENT, FOOD & BEVERAGE INGREDIENTS' last=' FOR YOUR BUSINESS' />
            <div className='row'>
                <div  className={`col-12 col-sm-4 ${styles.description}`}>
                    <p>DeFreitas Nutrition aims to assist dietary supplements, food & beverage companies create and 
                        implement R&D strategies and processes that enhance innovation; 
                        to identify innovative ingredients and technologies; and to 
                        commercialize safe and efficacious products that increase sales.</p>
                </div>
                <div className='col-12 col-sm-8'>
                    <img className={styles.homeFeaturesImg} src={HomeFeaturesImg} alt='Product Innovation, News, and Contact Us'/>
                    <ClickableButton buttonText="LET'S BRING NEW IDEAS TO LIFE" location='/product-innovation-strategy' buttonStyle={styles.buttonServices}/>
                    <ClickableButton buttonText='CATCH UP WITH OUR LATEST NEWS' location='/ingredient-innovation-branding' buttonStyle={styles.buttonBlog}/>
                    <ClickableButton buttonText='GET IN TOUCH WITH US!' location='/food-innovation-consulting' buttonStyle={styles.buttonContact}/>
                </div> 
            </div>
        </div>
    );
}

export default HomeFeatures;

