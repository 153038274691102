import React from 'react';
import { Helmet } from 'react-helmet';
import { METADATA } from '../../../shared/metaData';

const MetaData = (props) => {

    const metatags = METADATA.find((tag) => tag.id === props.id);
    return (
        <Helmet>
            <title>{ metatags.title }</title>
            <meta name='description' content= { metatags.description } />
        </Helmet>
    );
}

export default MetaData;