import React from 'react';
import styles from './ClickableButton.module.css';
import { Link } from 'react-router-dom';


const ClickableButton = (props) => {
    return(
        <Link className={`${styles.button} ${props.buttonStyle}`} to={props.location}>{props.buttonText}</Link>
    );
}

export default ClickableButton;

