export const METADATA = [

    {
        id: 1, //Home Page
        title: 'Innovation and Branding - Food - Ingredients | DeFreitas Nutrition',
        description: 'We’re ready to create and implement innovation and branding strategies to help you commercialize safe and efficacious ingredient products that increase sales'
    },

    {
        id: 2, //About DeFreitas Nutrition
        title: 'Ingredient Innovation-Food-Supplements -About us | DeFreitas Nutrition',
        description: "We’re committed to improving people's life and health by providing ingredient innovation strategies to dietary supplement, food & beverage companies. Learn more"
    },

    {
        id: 3, //About Zoraida DeFreitas
        title: 'Product Innovation Consultant- Zoraida DeFreitas | DeFreitas Nutrition',
        description: 'Zoraida has experience growing major dietary supplement, food and beverage brands. Learn how she can help you with your product innovation strategy'
    },

    {
        id: 4, //Services - Product Innovation Strategy
        title: 'Product Innovation - Food-Supplement-Ingredients | DeFreitas Nutrition',
        description: 'Differentiate your products with strong marketing and regulatory claims. Get started with a product innovation strategy that increases product speed to market'
    },

    {
        id: 5, //Services - Brand Narrative Strategy
        title: 'Brand Narrative Strategy - Food -Ingredients | DeFreitas Nutrition',
        description: 'We can help supplement, food & beverage companies stand out from competition with a brand narrative strategy that audiences will connect with.Get more info here'
    },

    {
        id: 6, //News
        title: 'Ingredient Innovation and Branding - News | DeFreitas Nutrition',
        description: 'We have 25+ years of expertise providing ingredient innovation and branding strategies to supplements & food industries. Learn what DeFreitas Nutrition is up to'
    },

    {
        id: 7, //Contact
        title: 'Food Innovation Consulting - Contact us | DeFreitas Nutrition',
        description: 'DeFreitas Nutrition is ready to exceed your consumer needs  through science. Contact us today for a supplement, beverage & food product innovation consultation'
    },
];