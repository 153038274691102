import React from 'react';
import styles from './HeroUnit.module.css';


const HeroUnit = (props) => {
    return(
        <div className='row' >
                <img className={styles.heroImage} src={props.heroImage} fluid alt='DeFreitas Nutrititon Banner'/>
                <h1 className={` ${styles.h1} ${props.heroH1Style}`}>{props.heroH1}</h1>
        </div>
    );
}

export default HeroUnit;