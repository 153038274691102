/* Home Section */
import brandStory from '../assets/images/home/brand-story.png';
import productInnovation from '../assets/images/common/product-innovation.png';
/* News Section */
import expoWest2020 from '../assets/images/news/Natural-products-expo-west-2020.jpg';
import expoWest2019 from '../assets/images/news/ExpoWest2019.jpg';
import sswExpo2018 from '../assets/images/news/SSWExpo2018.jpg';
import CyberColloids from '../assets/images/news/associate-partner-cybercolloids.jpg'
/* About Zoraida Section */
import educationImg from '../assets/images/about-zoraida/iowa-state-university.png';
import affiliationsImg from '../assets/images/about-zoraida/professional-development-and-affiliations.jpg';
import peerRevImg from '../assets/images/about-zoraida/published-peer-reviewed-articles.jpg';
import patentsImg from '../assets/images/about-zoraida/patents.jpg';
import otherPeerRevImg from '../assets/images/about-zoraida/writing-editing.jpg';
/* --------------------- */

/* Home Section: services */
export const SERVICES =
[
    {
        id: 0,
        name: 'PRODUCT INNOVATION STRATEGY',
        image: productInnovation,
        description: 'We can help you create a well-defined research and development strategy that is key for successful product commercialization.'        
    },
    {
        id: 1,
        name: 'BUILD YOUR BRAND STORY',
        image: brandStory,
        description: 'We can transform your innovation into a memorable story that sets you apart and helps you promote your product.'
     }
]

/* Product Innovation Section: */
/* DESCRIPTION */
export const productInnList = [
    'Translate market trends into products with real consumer benefits',
    'Differentiate your products with strong marketing and regulatory claims',
    'Develop intellectual property for sustainable market advantage',
    'Ensure your products meet high quality standards',
    'Increase speed to market by managing your innovation process',
];
/* SERVICES */
export const productInnServicesList = [
    'Market research and competitive analysis',
    'Product positioning and communication',
    'Stage-Gate® project management',
    'Global regulatory strategy and submissions',
    'Patent strategy and review'
];

/* Brand Story Section: description */
/* DESCRIPTION */
export const brandStoryList = [
    'Develop a brand promise that meet current consumer needs',
    'Stand-out from the crowd with science-based stories that resonate' 
];
/* SERVICES */
export const brandStoryServicesList = [
    'Brand story crafting',
    'Technical/marketing presentations content strategies',
    'Conferences and trade shows communication tactics',
    'White papers and peer-reviewed publications strategy and review',
    'Storytelling tactics to amplify the impact of your sales team', 
];

/* News Section */
export const items =
[
    {
        id: 0,
        header: 'CyberColloids specializes in texture and nutritional solutions for the Food & Beverage Industries',
        src: CyberColloids,
        altText:'Zoraida is the new Associate Parter at CyberColloids Ltd. Carrigaline, Co.',
        caption: 'Want to learn more? Please contact us at either zoraida@cybercolloids.net or zoraida@defreitas-nutrition.com'
    }, 
    {
        id: 1,
        header: 'Natural Products Expo West 2019',
        src: expoWest2019,
        altText:'Natural Products Expo West 2019',
        caption: 'DeFreitas Nutrition was at Natural Products Expo West helping Cura Global Health Inc. to commercialize their innovative Koji Mineral technology'
     },
     {
        id: 2,
        header: 'DeFreitas Nutrition was at Supply Side West Expo 2018',
        src: sswExpo2018,
        altText:'Supply Side West Expo 2018',
        caption: "'Koji fermentation: a science-based approach to deliver highly bioavailable and gentle on the system whole food minerals' by Zoraida DeFreitas"
     }
];

/* About Zoraida Section */

export const AFFILIATIONS =
[
    {
        id: 0,
        title1: 'ACADEMIC EDUCATION',
        description1:'<ul> <li>Iowa State University, Ames, IA - Post Doctorate, Research Associate</li> <li>Iowa State University, Ames, IA - Ph.D. Food Science & Human Nutrition and Animal Science</li> <li>Iowa State University, Ames, IA - M.S. Food Technology</li> </ul>',
        title2: 'EXECUTIVE EDUCATION',
        description2:'<ul> <li>The Warton School, University of Pennsylvania, Philadelphia, PA Executive Development Program (9.2 CEU) - General Management (2 weeks)</li></ul>',
        img: educationImg,
        altText: 'EDUCATION'
    },

    {
        id: 1,
        title1: 'CHAIRS, MEMBERSHIPS & COACHING',
        description1:'<ul> <li>Chair of the Senior Scientific Advisory Council - Council for Responsible Nutrition (3 years)</li> <li>Iowa State Food Science and Human Nutrition Industry Advisor (1 year)</li> <li>Institute of Food Technologists since 1988</li> <li>American Society for Nutrition since 2011</li> <li>Career Coach at the George Washington Carver Intership Leadership Retreat Iowa State University, July 8-9, 2016</li> </ul>',
        title2: '',
        description2:'',
        img: affiliationsImg,
        altText: 'PROFESSIONAL DEVELOPMENT & AFFILIATIONS'
    },

];

export const PUBLICATIONS = 
[
    {
        id: 0,
        title: 'PUBLISHED PEER-REVIEWED ARTICLES',
        description: '<ul><li>Lewis, BJ, Herrlinger, KA, Craig, TA, Mehring-Franklin, CE, DeFreitas, Z, Hinojosa-Laborde, C. 2013. Antihypertensive effect of passion fruit peel extract and its major bioactive components following acute supplementation in spontaneously hypertensive rats. J. Nutr. Biochem. doi: 10.1016/j. jnutbio.2012.11.003. [Epub ahead of print].</li> <li>C.L. Kruger, M. Murphy, Z. DeFreitas, F. Pfannkuch J. Heimbach. 2002. An Innovative Approach to the Determination of Safety for a Dietary Ingredient Derived From a New Source: Case Study Using a Crystalline Lutein Product. Food Che. Toxicol. 40 (11):1535-49.</li> <li>DeFreitas, Z. Sebranek, J.G., Olson, D.G., and Carr, J.M. 1997. Carrageenan effects on thermal stability of meat proteins. J. Food. Sci. 62(3):544-547.</li> <li>DeFreitas, Z., Sebranek, J. G., Olson, D.G., and Carr, J.M. 1997. Carrageenan effects on salt-soluble meat proteins in models systems. J. Food Sci. 62(3):539-543.</li> <li>DeFreitas, Z., Sebranek, J. G., Olson, and Carr, J. M. 1995. Freeze/thaw stability of cooked pork sausages as affected by salt, phosphate, pH, and carrageenan. J. Food Sci. 62(3):551-554.</li> <li>DeFreitas, Z., and Molins, R.A. 1991. Mechanically deboned pork in fermented meat spreads. J. Food Sci. 56(5):1185-1190.</li> <li>DeFreitas, Z., and Molins, R.A. 1988. Development of meat snack dips: chemical, physical, microbiological and sensory characteristics. J. Food Sci. 53(6):1645-1649.</li></ul>',
        img: peerRevImg
    },

    {
        id: 1,
        title: 'PATENTS',
        description: '<ul><li>"Solutions of carotenoids for use in foods and personal care products", US Patent #6,844,020.</li> <li>"Microcapsules having high carotenoid content, US Patent #6,663,900; EP1480736; JP 4989017.</li> <li>"Method of improving cardiovascular health", US Patent #8,021,698 B2.</li> <li>"Method of improving cardiovascular health", US Patent #8,673,370 B2.</li> <li>"Composition and method for reducing post-prandial blood glucose", US Patent #8,377877 B2.</li> </ul>',
        img: patentsImg
    },

    {
        id: 2,
        title: 'OTHER PUBLISHED PEER-REVIEWED ARTICLES (WRITING AND EDITING)',
        description: "<ul><li>Reddy MB, Armah SM. Impact of Iron-Enriched Aspergillus oryzae on Iron Bioavailability, Safety, and Gut Microbiota in Rats. J Agric Food Chem. 2018 Jun 20;66(24):6213-6218. doi: 10.1021/acs.jafc.8b01758. Epub 2018 Jun 11.</li> <li>Manju B Reddy, Seth M Armah, Jeanne W Stewart, Kimberly O O'Brien Iron Absorption from Iron-Enriched Aspergillus oryzae Is Similar to Ferrous Sulfate in Healthy Female Subjects Current Developments in Nutrition, Volume 2, Issue 3, 1 March 2018, nzy004, https://doi.org/10.1093/cdn/nzy004. </li> </ul>",
        img: otherPeerRevImg
    }
]

