import React from 'react';
import BodyHeader from '../../Shared/BodyHeader/BodyHeader';
import CuraLogo from '../../../assets/images/home/cura-global-health-logo.png';
import IngredionLogo from '../../../assets/images/home/ingredion-logo.jpg';
import IGYLogo from '../../../assets/images/home/igy-life-sciences-logo.png';
import PurFoodsLogo from '../../../assets/images/home/purFoods-logo.png';
import DianaLogo from '../../../assets/images/home/DianaPlantSciences-logo.jpg';
import GripLogo from '../../../assets/images/home/grip-ideas-logo.png';
import styles from './HomeClients.module.css';

const HomeClients = () =>{
    return(
        <div className={`container ${styles.container}`}>
             <BodyHeader first='CURRENT AND FORMER CLIENTS ' last=' - INGREDIENT MARKET'/>  
             <div className={`row ${styles.row1}`}>
                <div className='col-sm-4'>
                    <a href="https://www.curaglobalhealth.com/">
                        <i><img className={styles.curaLogo} src={CuraLogo} alt='Cura Global Health, Inc.'/></i>
                    </a>
                </div>
                <div className='col-sm-4'>
                    <a href="https://www.ingredion.com/">
                        <i><img className={styles.ingredionLogo} src={IngredionLogo} alt='Ingredion'/></i>
                    </a>
                </div>
                <div className='col-sm-4'>
                    <a href="https://www.igylifesciences.com/">
                        <i><img className={styles.igyLogo} src={IGYLogo} alt='IGY Immunotechnology & Life Sciences'/></i>
                    </a>
                </div>
             </div>
             <div className={`row ${styles.row2}`}>
                <div className='col-sm-4'>
                    <a href="https://www.purfoods.com/">
                        <i><img className={styles.purFoodsLogo} src={PurFoodsLogo} alt='PurFoods'/></i>
                    </a>
                </div>
                <div className='col-sm-4'>
                    <a href="https://www.symrise.com/">
                        <i><img className={styles.dianaLogo} src={DianaLogo} alt='Diana Plant Sciences currently Symrise'/></i>
                    </a>
                    <div  className={`col-12 col-sm-4 ${styles.dianaText}`}> currently <b>Symrise AG</b>  </div>
                </div>
                <div className='col-sm-4'>
                    <img className={styles.gripLogo} src={GripLogo} alt='Grip Ideas'/>
                </div>
             </div>
        </div>
   );      
}

export default HomeClients;