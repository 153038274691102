import React, {Component} from 'react';
import {  
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Logo from '../../../assets/images/common/DeFreitas-Nutrition-Logo.png';
import styles from './Header.module.css';



class Header extends Component {
    state = {
      isOpen: false
    };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
    render(){
        return(
            <>
                <Navbar expand='md' fixed='top' className={` ${styles.navBar}`}>
                    <div className={`container ${styles.container}`}>
                        <NavbarToggler className={`fa fa-bars ${styles.navbarToggler}`} onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav navbar>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret className={styles.navItem}>
                                        ABOUT US
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink className={`nav-link ${styles.navSubItem}`} to='/ingredient-innovation-strategy'>ABOUT DEFREITAS NUTRITION</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink className={`nav-link ${styles.navSubItem}`} to='/product-innovation-consultant'>READ ZORAIDA'S STORY</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret className={styles.navItem}>
                                        SERVICES
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink className={`nav-link ${styles.navSubItem}`} to='/product-innovation-strategy'>PRODUCT INNOVATION</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink className={`nav-link ${styles.navSubItem}`} to='/brand-narrative-strategy'>BUILD YOUR BRAND STORY</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <NavbarBrand href='/' expand='md'>
                                    <img className={styles.logo} src={Logo} alt='DeFreitas Nutrition' />
                                </NavbarBrand>

                                <NavItem>
                                    <NavLink className={`nav-link ${styles.navItem}`} to='/ingredient-innovation-branding'>NEWS</NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink className={`nav-link ${styles.navItem}`} to='/food-innovation-consulting'>CONTACT</NavLink>
                                </NavItem>

                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>
            </>
        );
    }
}

export default Header;


