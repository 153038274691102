import React, { Component } from 'react';
import HomeServices from '../../components/Home/HomeServices/HomeServices';
import { SERVICES } from '../../shared/constants';
import Header from '../../components/Shared/Header/Header';
import Footer from '../../components/Shared/Footer/Footer';
import HeroUnit from '../../components/Shared/HeroUnit/HeroUnit';
import HomeBanner from '../../assets/images/home/innovative-ingredients-and-technologies.jpg';
import HomeFeatures from '../../components/Home/HomeFeatures/HomeFeatures';
import HomeClients from '../../components/Home/HomeClients/HomeClients';
import MetaData from '../../components/Shared/MetaData/MetaData';
import styles from './Home.module.css';

class Home extends Component {
  render() {
    return (
      <div>
        <MetaData id={1} />
        <Header/>
        <HeroUnit 
            heroImage={HomeBanner} 
            heroH1='The Expertise for Building a Compelling 
                    Science-Based Story for your Products'
            heroH1Style={styles.heroH1}/>
        <HomeServices services={SERVICES}/>
        <HomeFeatures/>
        <HomeClients/>
        <Footer/>
      </div>
    );
  }
}

export default Home;
