import React, { Component } from 'react';
import {Route} from 'react-router-dom';
import Home from './Home/Home';
import AboutDFN from './AboutDFN/AboutDFN';
import AboutZDF from './AboutZDF/AboutZDF';
import ProductInnovation from './ProductInnovation/ProductInnovation';
import BrandStory from './BrandStory/BrandStory';
import News from './News/News';
import Contact from './Contact/Contact';

class Main extends Component {
  render() {
    return (
        <div>
        <Route path="/" exact component={Home} />  {/* home */}
        <Route path="/ingredient-innovation-strategy" exact component={AboutDFN} /> {/* about-defreitas-nutrition*/}
        <Route path="/product-innovation-consultant" exact component={AboutZDF} /> {/* about-zoraida */}
        <Route path="/product-innovation-strategy" exact component={ProductInnovation} /> {/* product-innovation */}
        <Route path="/brand-narrative-strategy" exact component={BrandStory} /> {/* brand-story */}
        <Route path="/ingredient-innovation-branding" exact component={News} /> {/* news */}
        <Route path="/food-innovation-consulting" exact component={Contact} /> {/* contact*/}
        </div>
    );
  }
}

export default Main;