import React, { Component } from 'react';
import Header from '../../components/Shared/Header/Header';
import HeroUnit from '../../components/Shared/HeroUnit/HeroUnit';
import Footer from '../../components/Shared/Footer/Footer';
import AboutZDFBanner from '../../assets/images/about-zoraida/zoraida-defreitas-story.jpg';
import AboutZDFStory from '../../components/AboutZDF/AboutZDFStory/AboutZDFStory';
import AboutZDFEducAffil from '../../components/AboutZDF/AboutZDFEducAffil/AboutZDFEducAffil';
import AboutZDFPublications from '../../components/AboutZDF/AboutZDFPublications/AboutZDFPublications';
import MetaData from '../../components/Shared/MetaData/MetaData';
import styles from './AboutZDF.module.css';

class AboutZDF extends Component {
  render() {
    return (
      <div>
        <MetaData id={3} />
        <Header/>
        <HeroUnit 
            heroImage={AboutZDFBanner} 
            heroH1='Meet Zoraida, Product Innovation Executive in the Food, 
                    Beverage and Dietary Supplement Industries'
            heroH1Style={styles.heroH1}/>
        <AboutZDFStory/>
        <AboutZDFEducAffil/>
        <AboutZDFPublications/>
        <Footer/>
      </div>
    );
  }
}

export default AboutZDF;