import React from 'react';
import ZDFImage from '../../../assets/images/about-zoraida/zoraida-defreitas.jpg';
import BodyHeader from '../../Shared/BodyHeader/BodyHeader';
import styles from './AboutZDFStory.module.css';

const AboutZDFStory = () => {
    return(
        <div className='container'>
            
            <BodyHeader first='READ ' last=" ZORAIDA'S STORY" />
            
            <div className='row'>
                <div className='col-12 col-sm-6'>
                    <img src={ZDFImage} alt='Zoraida DeFreitas' className={styles.ZDFImg}></img>
                    <p className={` ${styles.description} ${styles.colLeftStyle}`}> 
                        Zoraida is the founder and owner of DeFreitas Nutrition. 
                        She is an accomplished, results-oriented, global business executive, 
                        with 25 years experience in providing strategic and technical 
                        leadership to the food and beverage and dietary supplement industries.
                        <br></br>
                        <br></br>
                        During the last seven years, Zoraida have helped several customers 
                        in the dietary supplement, food and beverage industries to tell 
                        their stories to the trade. 
                        <br></br>
                        <br></br>
                        In 2017, she became a strategic member of the Cura Global Health. 
                        In her global role of V.P of Marketing and Sales, Zoraida helped 
                        Cura to commercialize their innovative Koji Mineral technology into the dietary 
                        supplement and functional food markets.
                    </p>
                </div>
                <div className='col-12 col-sm-6'>
                    <p className={` ${styles.description} ${styles.colRightStyle}`}> 
                        Zoraida is now a new Associate Partner at CyberColloids Ltd., Carrigaline, Co. 
                        Cork, Ireland. 
                        <br></br>
                        <br></br>
                        Zoraida has experience growing major dietary supplement ingredient 
                        brands and developing new ingredient market categories, such as 
                        FloraGLO® Lutein in the eye health supplement category. As a Vice 
                        President of Research and Development at Kemin, she developed and 
                        implemented a global research process, based on stage-gate principles, 
                        to identify, evaluate and commercialize safe, nutritional ingredients 
                        with human health benefits. This process was used successfully to 
                        launch six products, in-license five new technologies, and identify 
                        three new potential products. 
                        <br></br>
                        <br></br>
                        Prior to Kemin Health, she was with Quest International, formally 
                        an Unilever owned company, where she worked on fermented- and 
                        seaweed-derived hydrocolloids and hydrolyzed dairy and soy proteins 
                        for meat, dairy and/or nutraceutical applications.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutZDFStory;