import React from 'react';
import styles from './BodyHeader.module.css';

const BodyHeader = (props) => {
    return (
        <React.Fragment>
            <div className={`row ${styles.bodyHeader}`}>
                <div className='col-12'>
                    <strong>{props.first}</strong>
                    {props.last}
                </div>
            </div>
            <div className={`row ${styles.line} `}> </div>
        </React.Fragment>
    );
}

export default BodyHeader;