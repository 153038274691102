import React, { Component } from 'react';
import Header from '../../components/Shared/Header/Header';
import HeroUnit from '../../components/Shared/HeroUnit/HeroUnit';
import Footer from '../../components/Shared/Footer/Footer';
import NewsBanner from '../../assets/images/news/defreitas-nutrition-news.jpg';
import BodyHeader from '../../components/Shared/BodyHeader/BodyHeader';
import NewsCarousel from '../../components/News/NewsCarousel/NewsCarousel';
import MetaData from '../../components/Shared/MetaData/MetaData';
import styles from './News.module.css';

class News extends Component {
  render() {
    return (
      <div>
        <MetaData id={6} />
        <Header/>
        <HeroUnit 
            heroImage={NewsBanner} 
            heroH1='See What DeFreitas Nutrition Is Up To'
            heroH1Style={styles.heroH1}/>
        <BodyHeader first='NEWS & ' last='EVENTS' />
        <NewsCarousel/>
        <Footer/>
      </div>
    );
  }
}

export default News;

