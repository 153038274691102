import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import {items} from '../../../shared/constants';
import styles from './NewsCarousel.module.css';


class Example extends Component {
    state = { activeIndex: 0 };
    next = this.next.bind(this);
    previous = this.previous.bind(this);
    goToIndex = this.goToIndex.bind(this);
    onExiting = this.onExiting.bind(this);
    onExited = this.onExited.bind(this);
 
  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          key={item.id}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
        <img className={styles.backgrImg} src={item.src} alt={item.altText}></img>
        <CarouselCaption  className={styles.caption} captionHeader={item.header} captionText={item.caption} />
        </CarouselItem>
      );
    });

    return (
      <div className='container'>
          <div className='row'>
            <Carousel
                className={styles.carousel}
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
            >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
                <CarouselControl className={styles.controls} direction="prev" directionText="Previous" onClickHandler={this.previous} />
                <CarouselControl className={styles.controls} direction="next" directionText="Next" onClickHandler={this.next} />
            </Carousel>
        </div>
      </div>
    );
  }
}

export default Example;
