import React, { Component } from 'react';
import BodyHeader from '../../Shared/BodyHeader/BodyHeader';
import { PUBLICATIONS } from '../../../shared/constants';
import Publication from './Publication/Publication';

class AboutZDFPublications extends Component {
    render() {
        const aboutZDFPublications = PUBLICATIONS.map((publication) => {
            return (
                <div className='col-12 col-sm-6' key={publication.id}>
                    <Publication
                        src={publication.img}
                        alt={publication.title}
                        cardTitle={publication.title}
                        cardText={publication.description} />
                </div>
            );
        });
        return (
            <div className='container'>
                <BodyHeader first='PUBLICATIONS ' last=' & PATENTS ' />
                <div className='row'>
                    {aboutZDFPublications}
                </div>
            </div>
        );
    }
}

export default AboutZDFPublications; 









/* import React, { Component } from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, Button
} from 'reactstrap';
import BodyHeader from '../BodyHeader/BodyHeader';
import styles from './AboutZDFPublications.module.css';
import { PUBLICATIONS } from '../../shared/constants';

class AboutZDFPublications extends Component {
    state = {
        show: true,
    }

    hideContent() {
        this.setState({ show: false });
    }

    showContent() {
        this.setState({ show: true });
    }

    showContentHandler = (publication) => {
        return (
            <Card className={styles.card} show={this.show} variant="success" >
                <CardImg className={styles.img} top src={publication.img} alt='{publication.title}' />
                <CardBody>
                    <CardTitle className={styles.title}>{publication.title}</CardTitle>
                    <Button className={styles.button} close onClick={() => this.hideContent()} >
                        <span aria-hidden="true">+</span>
                    </Button>
                </CardBody>
            </Card>
        );
    }

    hideContentHandler = (publication) => {
        return (
            <Card className={styles.card} show={this.show} variant="success">
                <CardImg className={styles.img} top src={publication.img} alt={publication.title} />
                <CardBody>
                    <CardTitle className={styles.title}>{publication.title}</CardTitle>
                    <CardText className={styles.description}>
                        <p className={styles.description} dangerouslySetInnerHTML={{__html: publication.description}}/>
                    </CardText>
                    <Button className={styles.button} close onClick={() => this.showContent()} variant="outline-success">
                        <span aria-hidden="true">-</span>
                    </Button>
                </CardBody>
            </Card>
        );
    }

    render() {
        const showPublications = PUBLICATIONS.map((publication) => {
            return (
                <div className='col-12 col-sm-6'  key={publication.id}>
                    {this.showContentHandler(publication)}
                </div>
            );
        });

        const hidePublications = PUBLICATIONS.map((publication) => {
            return (
                <div className='col-12 col-sm-6'  key={publication.id}>
                    {this.hideContentHandler(publication)}
                </div>
            );
        });

        if (this.state.show) {
            return (
                <div className='container'>
                    <BodyHeader first='PUBLICATIONS ' last=' & PATENTS ' />
                    <div className='row'>
                        {showPublications}
                    </div>
                </div>  
            );
        }
        return (
            <div className='container'>
                    <BodyHeader first='PUBLICATIONS ' last=' & PATENTS ' />
                    <div className='row'>
                        {hidePublications}
                    </div>
            </div>  
        );
    }
}

export default AboutZDFPublications; */



