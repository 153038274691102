import React from 'react';
import { Media } from 'reactstrap';
import styles from './HomeServices.module.css';
import { SERVICES } from '../../../shared/constants';
import BodyHeader from '../../Shared/BodyHeader/BodyHeader';


const RenderHomeServicesItem = ({service}) => {
    return (
   
        <Media>
            <Media body>
                <Media heading className={styles.name}> {service.name} </Media>
                <p className={styles.description}> {service.description} </p>
            </Media>
            <Media className={styles.img}>
                <Media object src={service.image} alt={service.name}/>
            </Media>
        </Media>
    
    );     
}

const HomeServices = () => {

    const homeServices = SERVICES.map((service) => {
        return (
            <div className='col-12 col-md-6'  key={service.id}>
                    <RenderHomeServicesItem service={service} />
            </div>
        );
    });

    return (
        <div className='container'>
            <BodyHeader first='GROW ' last='YOUR BUSINESS' />   
            <div className='row'>
                {homeServices}
            </div>
        </div>
    );
}

export default HomeServices;