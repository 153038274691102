import React from 'react';
import BodyHeader from '../../Shared/BodyHeader/BodyHeader';
import styles from './AboutDFNSummary.module.css';
import ClickableButton from '../../Shared/ClickableButton/ClickableButton';

const SERVICES =
[
    {
        id: 0,
        name: 'DEFINING PRODUCT INNOVATION STRATEGIES',
        location: '/product-innovation-strategy'
    },
    {
        id: 1,
        name: 'TELLING YOUR BRAND STORY TO THE TRADE',
        location: '/brand-narrative-strategy'
     }
]

const RenderAboutSummaryItem = ({service}) => {
    return (
        <ClickableButton buttonText={service.name} location={service.location} buttonStyle={styles.button} />
    );     
}

const AboutSummary = () => {
    const aboutSummary = SERVICES.map((service) => {
        return (
            <div className='col-6'  key={service.id}>
                    <RenderAboutSummaryItem service={service} />
            </div>
        );
    });

    return (
        <div className='container'>
            <BodyHeader first='ABOUT ' last=' DEFREITAS NUTRITION' /> 
            <div className='row'>
                <div className='col-12'>
                    <p className={styles.description}> DeFreitas Nutrition is a privately owned company committed to 
                        improving people's life and health by providing science-focused 
                        ingredient and technology expertise to dietary supplement, food 
                        and beverage companies. 
                    </p>
                </div>
            </div> 
            <div className='row'>
                <div className='col-12'>
                    <h3 className={styles.h3}> DeFreitas Nutrition can help you grow your business by:</h3>
                </div>
            </div> 
            <div className='row'>
                {aboutSummary}
            </div>
        </div>
    );
}

export default AboutSummary;