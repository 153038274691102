import React from 'react';
import styles from './Footer.module.css';
import logo from '../../../assets/images/common/DeFreitas-Nutrition-Logo.png';
import { Link } from 'react-router-dom';

const Footer = (props) => {
    
    const buttonScrollHandler = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
   }

    return (
        <React.Fragment>
            <div className={styles.primaryFooter}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-4 offset-1 col-sm-3 mt-3'>
                            <h5 className={styles.h5Title}>MENU</h5>
                            <ul className={styles.footerMenu}>
                                <li><Link to='/ingredient-innovation-strategy'>About Us</Link></li>
                                <li><Link to='/product-innovation-strategy'>Product Innovation Strategy</Link></li>
                                <li><Link to='/brand-narrative-strategy'>Brand Story Strategy</Link></li>
                                <li><Link to='/ingredient-innovation-branding'>News</Link></li>
                                <li><Link to='/food-innovation-consulting'>Contact</Link></li>
                            </ul>
                        </div>
                        <div className='col-7 col-sm-4 mt-3'>
                            <a className='btn' onClick={buttonScrollHandler}>
                                <i className={`fa fa-sort-up ${styles.sortUp}`}></i>
                            </a>
                            <div className='row'>
                                <a className='btn' href='/'>
                                    <img className={styles.logoImg} src={logo} alt="DeFreitas-Nutrition" />
                                </a>
                                <div className={styles.address}>
                                    2625 North Loop Drive. Suite 2622<br />
                                    Ames, IA 50010<br />
                                    <br />
                                    +1 515 296-4097<br />
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-sm-4 mt-3 ${styles.connectSection}`}>
                            <h5 className={styles.h5Title}>CONNECT</h5>
                            <p className={styles.cta}> We're ready to design, develop and communicate the science you need to promote your product.</p>
                            <a className='btn btn-social-icon btn-lin' href="https://www.linkedin.com/company/defreitas-nutrition/"><i className={`fa fa-linkedin ${styles.linkedinIcon}`}></i></a>
                            <a className='btn btn-social-icon' href="mailto:us@defreitas-nutrition.com"><i className={`fa fa-envelope-o ${styles.emailIcon}`}></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.secondaryFooter}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <p>Copyright© 2020 DeFreitas LLC. All rights reserved.</p>
                        </div>
                        <div className='col-12 col-md-6'>
                            <p> Site designed by DeFreitas Nutrition</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Footer;