import React from 'react';
import ReactGA from 'react-ga';

//---- Google Analytics Event Tracking ----
/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const Event = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };