import React, { Component } from 'react';
import Main from './containers/Main';
import {BrowserRouter} from 'react-router-dom';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

//---- Google Analytics Tracking ----
const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});
//-----------------------------------

class App extends Component {
  
  componentDidMount() {
    ReactGA.pageview(window.location.pathname)	
  }

  render() {
    return (
      <div>
          <BrowserRouter history={history}>
            <Main/>
          </BrowserRouter>
      </div>
    );
  }
}

export default App;
