import React, { Component } from 'react';
import Header from '../../components/Shared/Header/Header';
import HeroUnit from '../../components/Shared/HeroUnit/HeroUnit';
import Footer from '../../components/Shared/Footer/Footer';
import BrandStoryBanner from '../../assets/images/service-brand-story/brand-identity.jpg';
import ServicesDFN from '../../components/ServicesDFN/ServicesDFN';
import BrandStoryImg from '../../assets/images/service-brand-story/service-brand-story.jpg';
import { brandStoryList } from '../../shared/constants';
import { brandStoryServicesList } from '../../shared/constants';
import BodyHeader from '../../components/Shared/BodyHeader/BodyHeader';
import MetaData from '../../components/Shared/MetaData/MetaData';
import styles from './BrandStory.module.css';

class BrandStory extends Component {
  render() {
    return (
      <div>
        <MetaData id={5} />
        <Header/>
        <HeroUnit 
            heroImage={BrandStoryBanner} 
            heroH1='Set your Company Apart with a Brand Story that Audiences Will Connect With'
            heroH1Style={styles.heroH1}/>
        <BodyHeader first='BRAND STORY ' last='STRATEGY' />
        <ServicesDFN 
          image= {BrandStoryImg}
          descriptionTitle='DeFreitas Nutrition can help you to:' 
          description={brandStoryList} 
          capTitle='Services' 
          capabilities={ brandStoryServicesList }
        />
        <Footer/>
      </div>
    );
  }
}

export default BrandStory;