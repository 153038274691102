import React, { Component } from 'react';
import Header from '../../components/Shared/Header/Header';
import HeroUnit from '../../components/Shared/HeroUnit/HeroUnit';
import Footer from '../../components/Shared/Footer/Footer';
import ProductInnovationBanner from '../../assets/images/service-product-innovation/grow-business-product-innovation-strategy.jpg';
import ServicesDFN from '../../components/ServicesDFN/ServicesDFN';
import ProductInnovationImg from '../../assets/images/service-product-innovation/service-product-innovation-strategy.jpg';
import BodyHeader from '../../components/Shared/BodyHeader/BodyHeader';
import { productInnList } from '../../shared/constants';
import { productInnServicesList } from '../../shared/constants';
import MetaData from '../../components/Shared/MetaData/MetaData';
import styles from './ProductInnovation.module.css';

class ProductInnovation extends Component {
  render() {
    return (
      <div>
        <MetaData id={4} />
        <Header/>
        <HeroUnit 
            heroImage={ProductInnovationBanner} 
            heroH1='Grow your Business through Science-based Innovations that Resonate'
            heroH1Style={styles.heroH1}/>
        <BodyHeader first='PRODUCT INNOVATION ' last='STRATEGY' />
        <ServicesDFN 
          image= {ProductInnovationImg}
          descriptionTitle='DeFreitas Nutrition can help you to:' 
          description={productInnList} 
          capTitle='Services' 
          capabilities={productInnServicesList}
        />
        <Footer/>
      </div>
    );
  }
}

export default ProductInnovation;