import React from 'react';
import styles from './ContactForm.module.css';
import iconLogo from '../../../assets/images/common/DeFreitas-Nutrition-IconLogo.png'
import { Form, FormGroup, Label, Input, Button} from 'reactstrap';
import {Event} from '../../Shared/TrackingGA/TrackingGA';

import PropTypes from "prop-types";
import axios from "axios";

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mailSent: false,
            error: null,
            name: null,
            phone: null,
            email: null,
            msg: null
        };
    }

    handleFormSubmit = e => {
        e.preventDefault();
        axios({
            method: "post",
            url: `/api/contact/index.php`,
            headers: { "content-type": "application/json" },
            data: this.state
        })
            .then(result => {
                if (result.data.sent) {
                    this.setState({
                        mailSent: result.data.sent
                    });
                    this.setState({ error: false });
                } else {
                    this.setState({ error: true });
                }
            })
            .catch(error => this.setState({ error: error.message }));
        Event('Contact form','User submited the form','Contact us page')
    };

    handleChange = (e, field) => {
        let value = e.target.value;
        let updateValue = {};
        updateValue[field] = value;
        this.setState(updateValue);
    };

    render() {
        return (
            <div className={` container ${styles.container}`}>
                <div className='row'>
                    <div className={` col-6 ${styles.colLeft}`}>
                        <img className={styles.iconLogo} src={iconLogo} alt='DeFreitas Nutrition Logo' />
                        <div className={styles.contactInfo}>
                            <p> +1 515 296-4097
                                <br></br>
                                us@defreitas-nutrition.com </p>
                        </div>
                        <div className={styles.line}> </div>
                        <div className={styles.contactInfo}>
                            <p> 2625 North Loop Drive <br></br>
                                Suite 2622 <br></br>
                                Ames, IA 50010 </p>
                        </div>
                    </div>
                    <div className={` col-6 ${styles.colRight}`}>


                        <Form className={styles.form}>
                            <FormGroup>
                                <Label  className={styles.label}>Name: </Label>
                                <Input
                                    className={styles.inputText}
                                    type='text'
                                    name='name'
                                    id='personName'
                                    placeholder='Your name'
                                    value={this.state.name}
                                    valid={this.state.name}
                                    invalid={!this.state.name}
                                    onChange={(e) => this.handleChange(e, 'name')}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label  className={styles.label}>Phone: </Label>
                                <Input
                                    className={styles.inputText}
                                    type='number'
                                    name='phone'
                                    id='phoneNumber'
                                    placeholder='Your phone'
                                    value={this.state.phone}
                                    valid={this.state.phone}
                                    invalid={!this.state.phone}
                                    onChange={(e) => this.handleChange(e, 'phone')}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label className={styles.label}>Email: </Label>
                                <Input
                                    className={styles.inputText}
                                    type='email'
                                    name='email'
                                    id='personEmail'
                                    placeholder='Your email'
                                    value={this.state.email}
                                    valid={this.state.email}
                                    invalid={!this.state.email}
                                    onChange={(e) => this.handleChange(e, 'email')}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label  className={styles.label}> Message: </Label>
                                <Input
                                    className={styles.inputText}
                                    type='textarea'
                                    name='message'
                                    id='personMessage'
                                    placeholder='How can we work together?'
                                    value={this.state.msg}
                                    valid={this.state.msg}
                                    invalid={!this.state.msg}
                                    onChange={(e) => this.handleChange(e, 'msg')} 
                                />
                            </FormGroup>
                            <Button onClick={e => this.handleFormSubmit(e)} value="Submit" className={styles.button}>Submit</Button>
                            <div><br />
                                {this.state.mailSent && <div className={styles.success} >Thank you for contacting us</div>}
                                {this.state.error && <div className={styles.error} >Sorry, we have some problems</div>}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactForm;
//propTypes for the component
ContactForm.propTypes = {
    config: PropTypes.object.isRequired
};

        