import React, { Component } from 'react';
import Header from '../../components/Shared/Header/Header';
import HeroUnit from '../../components/Shared/HeroUnit/HeroUnit';
import Footer from '../../components/Shared/Footer/Footer';
import AboutDFNBanner from '../../assets/images/about/about-defreitas-nutrition.jpg';
import AboutDFNSummary from '../../components/AboutDFN/AboutDFNSummary/AboutDFNSummary';
import AboutDFNValues from '../../components/AboutDFN/AboutDFNValues/AboutDFNValues';
import MetaData from '../../components/Shared/MetaData/MetaData';
import styles from './AboutDFN.module.css';

class AboutDFN extends Component {
  render() {
    return (
      <div>
        <MetaData id={2} />
        <Header/>
        <HeroUnit 
            heroImage={AboutDFNBanner} 
            heroH1='We Provide a Complete Package, from New 
                    Ingredient Concepts to Product Commercialization'
            heroH1Style={styles.heroH1}/>
        <AboutDFNSummary/>
        <AboutDFNValues/>
        <Footer/>
      </div>
    );
  }
}

export default AboutDFN;