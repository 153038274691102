import React from 'react';
import styles from './ServicesDFN.module.css';

const ServicesDFN = (props) => {
    return(
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <img className={styles.img} src={props.image} alt='Product Innovation Strategy'></img>
                </div>
                <div className='col-12 col-md-6'>
                    <p className={styles.descripTitle}>{props.descriptionTitle}</p>
                    <ul className={styles.description}>{props.description.map((item) => {
                            return <li>{item}</li>
                        })}
                    </ul>
                    <p className={styles.capTitle}>{props.capTitle}</p>
                    <ul className={styles.description}>{props.capabilities.map((item) => {
                            return <li>{item}</li>
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ServicesDFN;
